import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Swal from "sweetalert2";
import {deleteAcc, logout} from "../../redux-components/actions/userActions";
import {deleteAllGroceryFavorites} from "../../redux-components/actions/groceryActions";
import {updateBgQuery} from "../../redux-components/actions/settingsActions";
import {categoryClear} from "../../redux-components/actions/reminderActions";
import {REMINDER_CATEGORY_CLEAR_ACKNOWLEDGE} from "../../redux-components/constants/reminderConstants";
import {GROCERY_FAVORITE_DELETE_ALL_ACKNOWLEDGE} from "../../redux-components/constants/groceryConstants";

const Settings = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const reminderClear = useSelector(state => state.reminderClear);
    const { success: reminderClearSuccess, error: reminderClearError } = reminderClear;

    const asideDuration = 0.3;
    const collapseDuration = 0.3;

    const buttonVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 24,
                duration: asideDuration
            }
        },
        exit: {
            opacity: 0,
            x: -20,
            transition: {
                duration: collapseDuration
            }
        }
    };

    const buttonContainerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05,
                delayChildren: 0.1,
                duration: asideDuration
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: collapseDuration,
                when: "afterChildren"
            }
        }
    };

    useEffect(() => {
        if(!userInfo) {
            history('/login?next=/dev');
        } else if(!userInfo) {
            history('/')
        }
    }, [userInfo, history])

    const [openOptions, setOpenOptions] = useState(false);

    const handleDeleteAccount = (e) => {
        if(!userInfo.isAdmin) {
            e.preventDefault();
            Swal.fire({
                title: 'Are you Sure?',
                text: 'This action is irreversible!',
                icon: 'warning',
                confirmButtonText: 'Yes Delete my Account',
                showCancelButton: true
            }).then((value) => {
                if(value.isConfirmed) {
                    try {
                        dispatch(deleteAcc(userInfo._id));
                        Swal.fire({
                            title: 'Account Deleted',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            showCancelButton: false
                        }).then((val) => {
                            dispatch(logout());
                            history('/');
                        })
                    } catch (error) {

                    }
                }
            });
        }
    };

    const handleClearReminders = () => {
        Swal.fire({
            title: 'Are you Sure?',
            text: 'This action is irreversible!',
            icon: 'warning',
            confirmButtonText: 'Yes Delete my Reminders',
            showCancelButton: true
        }).then((value) => {
            if(value.isConfirmed) {
                dispatch(categoryClear());
                Swal.fire({
                    title: 'Reminders List Cleared',
                    text: reminderClearError,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    showCancelButton: false
                }).then((val) => {
                    dispatch({type: REMINDER_CATEGORY_CLEAR_ACKNOWLEDGE});
                })
            }
        });
    };

    const handleClearFavorites = () => {
        Swal.fire({
            title: 'Are you Sure?',
            text: 'This action is irreversible!',
            icon: 'warning',
            confirmButtonText: 'Yes Delete all of my Favorites',
            showCancelButton: true
        }).then((value) => {
            if(value.isConfirmed) {
                dispatch(deleteAllGroceryFavorites());
                Swal.fire({
                    title: 'Success',
                    text: 'Your Favorites were cleared',
                    icon: 'success'
                }).then((val) => {
                    dispatch({type: GROCERY_FAVORITE_DELETE_ALL_ACKNOWLEDGE});
                })
            }
        });
    };

    const handleUpdateBackground = async() => {
        const inputValue = await Swal.fire({
            title: 'Enter a query for background images!',
            input: 'text',
            inputPlaceholder: 'nature, christmas, fall, anything!',
            inputValue: userInfo.settings.bgQuery ?? "",
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
        });

        if (inputValue) {
            // Do something with the input value, such as displaying it on the page.
            if(inputValue.value) {
                dispatch(updateBgQuery(inputValue.value));
                Swal.fire({
                    title: 'Success',
                    text: 'Background Image Query Updated',
                    icon: 'success'
                })
            }
        }
    };

    return (
        <div className='items-center justify-center flex flex-col text-center mx-auto'>
            <Helmet>
                <title>{userInfo.username}'s Settings</title>
            </Helmet>
            <div className="flex backdrop-filter backdrop-blur-md bg-opacity-40 bg-black h-2/3 rounded-md">
                <motion.aside
                    className={`md:hidden flex flex-row bg-blue-500 backdrop-filter backdrop-blur-md bg-opacity-40`}
                    initial={{width: "40px"}}
                    animate={{width: openOptions ? "200px" : "40px"}}
                    transition={{duration: asideDuration}}
                >
                    <div onClick={() => setOpenOptions(!openOptions)}
                         className={'flex justify-center content-center items-center text-center h-full select-none cursor-pointer'}>
                        <h1 className={'vertical-text text-lg content-center items-center justify-center mx-auto pl-1 text-white font-bold'}>Options</h1>
                    </div>
                    <motion.nav
                        className={`flex flex-col content-center items-center justify-center p-2 overflow-hidden`}
                        initial={{width: 0}}
                        animate={{width: openOptions ? "160px" : 0}}
                        transition={{duration: 0.3}}
                    >
                        <AnimatePresence mode="wait">
                            {openOptions && (
                                <motion.div
                                    className="space-y-4 w-full"
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    variants={buttonContainerVariants}
                                    key="button-container"
                                >
                                    <motion.button
                                        variants={buttonVariants}
                                        onClick={handleDeleteAccount}
                                        className={`w-full ${userInfo.isAdmin ? "bg-gray-500 hover:bg-gray-600" : "bg-red-500 hover:bg-red-600"} text-white px-2 py-2 rounded-md focus:outline-none whitespace-nowrap overflow-hidden text-ellipsis`}
                                    >
                                        Delete Account
                                    </motion.button>
                                    <motion.button
                                        variants={buttonVariants}
                                        onClick={handleClearReminders}
                                        className="w-full bg-blue-500 text-white px-2 py-2 rounded-md hover:bg-blue-600 focus:outline-none whitespace-nowrap overflow-hidden text-ellipsis"
                                    >
                                        Clear Reminders
                                    </motion.button>
                                    <motion.button
                                        variants={buttonVariants}
                                        onClick={handleClearFavorites}
                                        className="w-full bg-green-500 text-white px-2 py-2 rounded-md hover:bg-green-600 focus:outline-none whitespace-nowrap overflow-hidden text-ellipsis"
                                    >
                                        Clear Favorites
                                    </motion.button>
                                    <motion.button
                                        variants={buttonVariants}
                                        className="w-full bg-purple-500 text-white px-2 py-2 rounded-md hover:bg-purple-600 focus:outline-none whitespace-nowrap overflow-hidden text-ellipsis"
                                    >
                                        <Link to={'/resetpassword'} className="block w-full">
                                            Reset Password
                                        </Link>
                                    </motion.button>
                                    <motion.button
                                        variants={buttonVariants}
                                        onClick={handleUpdateBackground}
                                        className="w-full bg-indigo-500 text-white px-2 py-2 rounded-md hover:bg-indigo-600 focus:outline-none whitespace-nowrap overflow-hidden text-ellipsis"
                                    >
                                        Background
                                    </motion.button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.nav>
                </motion.aside>
                <aside
                    className="hidden md:block w-1/4 bg-blue-500 backdrop-filter backdrop-blur-md bg-opacity-40 text-white rounded-l-md p-6">
                    <h1 className="text-2xl font-semibold mb-4">Settings</h1>
                    <nav>
                        <div className="space-y-4">
                            <button
                                onClick={handleDeleteAccount}
                                className={`w-full ${userInfo.isAdmin ? "bg-gray-500 hover:bg-gray-600" : "bg-red-500 hover:bg-red-600"} text-white px-4 py-2 rounded-md focus:outline-none`}
                            >
                                Delete Account
                            </button>
                            <button
                                onClick={handleClearReminders}
                                className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
                            >
                                Clear Reminders
                            </button>
                            <button
                                onClick={handleClearFavorites}
                                className="w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none"
                            >
                                Clear Favorites
                            </button>
                            <button
                                type={'button'}
                                className="w-full bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600 focus:outline-none"
                            >
                                <Link to={'/resetpassword'}>
                                    Reset Password
                                </Link>
                            </button>
                            <button
                                onClick={handleUpdateBackground}
                                className="w-full bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 focus:outline-none"
                            >
                                Background
                            </button>
                        </div>
                    </nav>
                </aside>
                <motion.main
                    className="flex justify-center content-center items-center flex-grow md:p-6"
                    animate={{
                        width: openOptions ? "calc(100% - 200px)" : ""
                    }}
                    transition={{duration: 0.3}}
                >
                    <div className="text-white rounded-md overflow-hidden p-6">
                        <h1 className="text-lg md:text-2xl font-semibold mb-4">Account Details</h1>
                        <div className="mb-4">
                            <p className="text-white mb-2 text-sm">
                                <span className="font-semibold">Username:</span> {userInfo.username}
                            </p>
                            <p className="text-white mb-2 text-sm">
                                <span className="font-semibold">Email:</span> {userInfo.email}
                            </p>
                            <p className="text-white mb-2 text-sm">
                                <span className="font-semibold">Join Date:</span> {userInfo.createdAt.substring(0, 10)}
                            </p>
                            <p className="text-white mb-4 text-sm">
                                <span className="font-semibold">Role:</span> {userInfo.role}
                            </p>
                        </div>
                        <div className="mt-4 text-sm">
                            <Link to="/" className="text-white underline">
                                Back to Dashboard
                            </Link>
                        </div>
                    </div>
                </motion.main>
            </div>
        </div>
    );
};

export default Settings;